<template>
 <div id="PaymentMethods">
  <b-container>
   <b-row>
    <b-col lg="7">
     <div class="PaymentMethod">
      <div class="HeaderPaymentMethod">
       <h4><i class="fa fa-credit-card"></i>Payment Method</h4>
      </div>
      <section
       v-on:click="activeTap = '1'"
       :class="[activeTap === '1' ? 'active' : '']"
       class="option-edit-domain"
      >
       <div class="option-one">
        <b-container>
         <b-row>
          <b-col>
           <header class="radio-option">
            <label>
             <div
              id="ins"
              class="radio-round"
              :class="[activeTap === '1' ? 'colorIns' : '']"
             >
              <input type="radio" />
              <ins class="iCheck-helper"></ins>
             </div>
             <span>Pay Cash</span>
            </label>
           </header>
          </b-col>
          <b-col>
           <div class="icon">
            <i class="fas fa-money-bill-wave icon-i"></i>
           </div>
          </b-col>
         </b-row>
        </b-container>
       </div>
      </section>
      <section
       v-on:click="activeTap = '2'"
       :class="[activeTap === '2' ? 'active' : '']"
       class="option-edit-domain"
      >
       <div class="option-one">
        <b-container>
         <b-row>
          <b-col>
           <header class="radio-option">
            <label>
             <div
              id="ins"
              class="radio-round"
              :class="[activeTap === '2' ? 'colorIns' : '']"
             >
              <input type="radio" />
              <ins class="iCheck-helper"></ins>
             </div>
             <span>Certified check</span>
            </label>
           </header>
          </b-col>
          <b-col>
           <div class="icon">
            <i class="fas fa-money-check icon-i"></i>
           </div>
          </b-col>
         </b-row>
        </b-container>
       </div>
      </section>
      <section
       v-on:click="activeTap = '3'"
       :class="[activeTap === '3' ? 'active' : '']"
       class="option-edit-domain"
      >
       <div class="option-one">
        <b-container>
         <b-row>
          <b-col>
           <header class="radio-option">
            <label>
             <div
              id="ins"
              class="radio-round"
              :class="[activeTap === '3' ? 'colorIns' : '']"
             >
              <input type="radio" />
              <ins class="iCheck-helper"></ins>
             </div>
             <span>Sadad Service</span>
            </label>
           </header>
          </b-col>
          <b-col>
           <div class="icon">
            <i class="fas fa-credit-card icon-i"></i>
           </div>
          </b-col>
         </b-row>
        </b-container>
       </div>
      </section>
      <section
       v-on:click="activeTap = '4'"
       :class="[activeTap === '4' ? 'active' : '']"
       class="option-edit-domain"
      >
       <div class="option-one">
        <b-container>
         <b-row>
          <b-col>
           <header class="radio-option">
            <label>
             <div
              id="ins"
              class="radio-round"
              :class="[activeTap === '4' ? 'colorIns' : '']"
             >
              <input type="radio" />
              <ins class="iCheck-helper"></ins>
             </div>
             <span>Tadawul Service</span>
            </label>
           </header>
          </b-col>
          <b-col>
           <div class="icon">
            <i class="fas fa-credit-card icon-i"></i>
           </div>
          </b-col>
         </b-row>
        </b-container>
       </div>
      </section>
      <section
       v-on:click="activeTap = '5'"
       :class="[activeTap === '5' ? 'active' : '']"
       class="option-edit-domain"
      >
       <div class="option-one">
        <b-container>
         <b-row>
          <b-col>
           <header class="radio-option">
            <label>
             <div
              id="ins"
              class="radio-round"
              :class="[activeTap === '5' ? 'colorIns' : '']"
             >
              <input type="radio" />
              <ins class="iCheck-helper"></ins>
             </div>
             <span>Credit Card Payment</span>
            </label>
           </header>
          </b-col>
          <b-col>
           <div class="icon">
            <i class="fas fa-credit-card icon-i"></i>
           </div>
          </b-col>
         </b-row>
        </b-container>
       </div>
      </section>
     </div>
    </b-col>
    <b-col lg="5">
     <div class="scrollingPanelContainer">
      <div class="title-order-summary">
       <h5><i class="fas fa-file-invoice-dollar"></i>Payment Requirements</h5>
      </div>
      <div class="order-summary">
       <div v-if="activeTap === '1'" class="PayCash">
        <div class="invoice-number">
         <h5>Invoice Number</h5>
         <p>958723</p>
        </div>
        <div class="Requirements">
         <h5>Requirements When Paying</h5>
         <p>ID Proof</p>
        </div>
        <div class="MakeAnAppointment">
         <h5>Make An Appointment</h5>
         <button>Contact Us</button>
        </div>
       </div>
       <div v-if="activeTap === '2'" class="BankTransfer">
        <div class="BankTransfer-Details">
         <div class="Details">
          <span>Bank Account Name :</span>
          <span class="info-bank">Transahara Company</span>
         </div>
         <div class="Details">
          <span>Bank name :</span>
          <span class="info-bank"> ATIB Bank / Qarji Branch</span>
         </div>
         <div class="Details">
          <span>Account number:</span>
          <span class="info-bank">135.117.895</span>
         </div>
         <div class="Details">
          <span>Invoice Number :</span>
          <span class="info-bank">17692</span>
         </div>
        </div>
       </div>
       <div v-if="activeTap === '3'" class="SadadService">
        <div class="sadad">
         <div class="name-methode-payment">
          <h4>Sadad Service</h4>
         </div>
         <!-- sadad form req -->

         <section class="section-form">
          <b-form>
           <span>Phone Number</span>
           <b-form-input
            id="inline-form-input-name"
            class="mb-2 mr-sm-2 mb-sm-0 inputs"
            type="number"
            placeholder="002189********"
           ></b-form-input>
           <br />
           <span> Year of Birth</span>
           <b-form-input
            type="date"
            id="inline-form-input-name"
            class="mb-2 mr-sm-2 mb-sm-0"
           ></b-form-input>
           <br />
           <div class="PrivacyPolicy">
            <label>
             <div id="inss" class="radio-round">
              <input type="radio" />
              <ins class="iCheck-helper"></ins>
             </div>
             <span
              >I have read and agree to the
              <strong>
               <router-link class="link" to="/privacy-policy">
                Terms of Service</router-link
               ></strong
              ></span
             >
            </label>
           </div>
           <div class="group-btn">
            <b-container>
             <b-row>
              <b-col>
               <b-button
                >Continue <i class="fas fa-arrow-circle-right"></i
               ></b-button>
              </b-col>
              <b-col>
               <b-button class="Close"
                >Close <i class="fas fa-times"></i></b-button
              ></b-col>
             </b-row>
            </b-container>
           </div>
           <br />
           <div
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
           >
            <strong>For assistance! :</strong>
            contact the technical team.
           </div>
          </b-form>
         </section>
         <!-- sadad form response -->
         <section hidden class="section-form">
          <div
           class="alert alert-success alert-dismissible fade show"
           role="alert"
          >
           The verification code has been successfully sent to your phone
          </div>
          <b-form class="form">
           <span>Verification Code Received From SADAD Service</span>
           <b-form-input
            id="inline-form-input-name"
            class="mb-2 mr-sm-2 mb-sm-0 inputs"
            type="number"
            placeholder="********"
           ></b-form-input>
           <br />
           <span class="try-agin"
            >I did not receive the verification code ?
            <strong><a href="#">Try again</a></strong>
           </span>
           <br />
           <div class="group-btn">
            <b-container>
             <b-row>
              <b-col>
               <b-button
                >Continue <i class="fas fa-arrow-circle-right"></i
               ></b-button>
              </b-col>
              <b-col>
               <b-button class="Close"
                >Close <i class="fas fa-times"></i></b-button
              ></b-col>
             </b-row>
            </b-container>
           </div>
           <br />
           <div
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
           >
            <strong>For assistance! :</strong>
            contact the technical team.
           </div>
          </b-form>
         </section>
        </div>
       </div>
       <div v-if="activeTap === '4'" class="TadawulService">
        <div class="tadawul">
         <div class="name-methode-payment">
          <h4>Tadawul Service</h4>
         </div>
         <div class="detailes">
          <p>
           This order form is provided in a secure environment and to help
           protect against fraud your current IP address
           <strong>(55.55.55.555)</strong> is being logged.
          </p>
         </div>
         <div class="PrivacyPolicy">
          <label>
           <div id="inss" class="radio-round">
            <input type="radio" />
            <ins class="iCheck-helper"></ins>
           </div>
           <span
            >I have read and agree to the
            <strong>
             <router-link class="link" to="/privacy-policy">
              Terms of Service</router-link
             ></strong
            ></span
           >
          </label>
         </div>
         <div class="btnContinue">
          <button> <router-link style="color:blanchedalmond; text-decoration:none;" to="/Invoice">Continue</router-link>  <i class="fas fa-arrow-circle-right"></i></button>
         </div>
         <div class="aleart-secure">
          <p><i class="fa fa-lock"></i> Encrypted and Secure Payment Process</p>
         </div>
        </div>
       </div>
       <div v-if="activeTap === '5'" class="CreditCardPayment">
        <div class="CreditCard">
         <div class="namePayment"><h4>Credit Card Payment</h4></div>
         <div class="bodyFormInputs">
          <section class="section-form">
           <b-form class="form">
            <span>Cart Number</span>
            <b-form-input
             id="inline-form-input-name"
             class="mb-2 mr-sm-2 mb-sm-0 inputs"
             type="number"
             placeholder="*********"
            ></b-form-input>
            <br />
            <span> Expiration Date</span>
            <b-form-input
             type="month"
             id="inline-form-input-name"
             class="mb-2 mr-sm-2 mb-sm-0"
            ></b-form-input>
            <br />
            <span>Name On Card</span>
            <b-form-input
             placeholder="example name cart 'ATIB'"
             type="text"
             id="inline-form-input-name"
             class="mb-2 mr-sm-2 mb-sm-0"
            ></b-form-input>
            <br />
            <span>Email Address</span>
            <b-form-input
             placeholder="example@gmail.com"
             type="email"
             id="inline-form-input-name"
             class="mb-2 mr-sm-2 mb-sm-0"
            ></b-form-input>
            <br />

            <div class="PrivacyPolicy">
             <label>
              <div id="inss" class="radio-round">
               <input type="radio" />
               <ins class="iCheck-helper"></ins>
              </div>
              <span
               >I have read and agree to the
               <strong>
                <router-link class="link" to="/privacy-policy">
                 Terms of Service</router-link
                ></strong
               ></span
              >
             </label>
            </div>
            <div class="group-btn">
             <b-container>
              <b-row>
               <b-col>
                <b-button
                 >Continue <i class="fas fa-arrow-circle-right"></i
                ></b-button>
               </b-col>
               <b-col>
                <b-button class="Close"
                 >Close <i class="fas fa-times"></i></b-button
               ></b-col>
              </b-row>
             </b-container>
            </div>
            <br />
            <div class="detailes">
             <p>
              This order form is provided in a secure environment and to help
              protect against fraud your current IP address
              <strong>(55.55.55.555)</strong> is being logged.
             </p>
            </div>
            <div
             class="alert alert-warning alert-dismissible fade show"
             role="alert"
            >
             <strong>For assistance! :</strong>
             contact the technical team.
            </div>
           </b-form>
          </section>
         </div>
         <div class="aleart-secure">
          <p><i class="fa fa-lock"></i> Encrypted and Secure Payment Process</p>
         </div>
        </div>
       </div>
      </div>
      <div class="PaymentCards">
       <b-container>
        <b-row cols-lg="4">
         <b-col>
          <div class="img"><img src="../assets/payment.png" alt="" /></div>
         </b-col>
         <b-col>
          <div class="img"><img src="../assets/payment-1.png" alt="" /></div>
         </b-col>
         <b-col>
          <div class="img"><img src="../assets/payment-3.png" alt="" /></div>
         </b-col>
         <b-col>
          <div class="img"><img src="../assets/payment-4.png" alt="" /></div>
         </b-col>
        </b-row>
       </b-container>
      </div>
     </div>
    </b-col>
   </b-row>
  </b-container>
 </div>
</template>

<script>
export default {
 name: "PaymentMethods",
 data: () => {
  return {
   activeTap: "1",
  };
 },
 methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#PaymentMethods {
 position: relative;
 padding: 70px 0;

 @mixin style-headers {
  padding: 10px 20px;
  background: map-get($branding, them-color-option-tow);
  border-radius: 2px;
 }
 @mixin style-text-header {
  color: blanchedalmond;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
 }
 @mixin style-icon-befor-header {
  margin-right: 5px;
  font-size: 19px;
 }
 @mixin style-list {
  font-size: 15px;
  border-bottom: 1px solid #f1ebeb;
  padding: 10px;
  margin-bottom: 0;
  color: #5b5858;
 }
 @mixin product-info {
  box-shadow: 0 0 13px 0 #00000042;
  margin-top: 10px;
  padding: 20px 15px;
  background-color: #fafafa;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #efefef;
  margin: 8px 0 10px 0;
 }
 @mixin text-in-span-summary {
  font-size: 13px;
  color: #000;
  font-weight: 600;
 }
 @mixin PrivacyPolicy {
  margin: 20px 0;
  label {
   display: flex;
   align-items: center;
   font-size: 14px;
   margin: 10px 0 3px 0;
   align-content: center;
   justify-content: center;
  }
  .radio-round {
   position: relative;
   background-position: -120px 0;
   display: inline-block;
   vertical-align: middle;
   margin: 0;
   padding: 3px 2px !important;
   width: 24px;
   height: 24px;
   background: #fff;
   border: 1px solid #4c4c4c;
   border-radius: 3px;
   cursor: pointer;
   border-radius: 11%;
   input {
    position: absolute;
    top: -20%;
    left: -20%;
    display: block;
    width: 140%;
    height: 140%;
    margin: 0px;
    padding: 0px;
    background: rgb(255, 255, 255);
    border: 0px;
    opacity: 0;
   }
   ins {
    position: absolute;
    top: -20%;
    left: -20%;
    display: block;
    width: 140%;
    height: 140%;
    margin: 0px;
    padding: 0px;
    background: rgb(255, 255, 255);
    border: 0px;
    opacity: 0;
   }
  }

  span {
   margin-left: 7px;
   color: #666;
   strong {
    color: #0d235a;
    .link {
     text-decoration: none;
    }
   }
  }
  .radio-round::before {
   content: "\2713";
   height: 16px;
   width: 16px;
   color: #fff;
   font-family: "Font Awesome 5 Pro";
   font-weight: 900;
   line-height: 1;
   font-size: 21px;
  }
 }
 .PaymentMethod {
  .HeaderPaymentMethod {
   @include style-headers;
   h4 {
    @include style-text-header;
    .fa-credit-card {
     @include style-icon-befor-header;
    }
   }
  }
  .option-edit-domain {
   background-color: map-get($branding, them-color-main-section);
   color: map-get($branding, color-text);
   margin: 15px 0;
   border-radius: 6px;
   padding: 20px 10px;
   cursor: pointer;
   .option-one {
    .radio-option {
     padding: 0 20px;
     label {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
     }
     .radio-round {
      position: relative;
      background-position: -120px 0;
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      padding: 3px 2px !important;
      width: 24px;
      height: 24px;
      background: #fff;
      border: 2px solid #8d3de2;
      border-radius: 3px;
      cursor: pointer;
      border-radius: 50%;
      /* border-color: #06155a;
    background: #06155a;
    min-width: 18px;
    min-height: 18px; */
      input {
       position: absolute;
       top: -20%;
       left: -20%;
       display: block;
       width: 140%;
       height: 140%;
       margin: 0px;
       padding: 0px;
       background: rgb(255, 255, 255);
       border: 0px;
       opacity: 0;
      }
      ins {
       position: absolute;
       top: -20%;
       left: -20%;
       display: block;
       width: 140%;
       height: 140%;
       margin: 0px;
       padding: 0px;
       background: rgb(255, 255, 255);
       border: 0px;
       opacity: 0;
      }
     }
     span {
      margin-left: 7px;
     }
     .colorIns {
      background-color: #8d3de2;
     }
     .radio-round::before {
      content: "\2713";
      height: 16px;
      width: 16px;
      color: #fff;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      line-height: 1;
      font-size: 19px;
     }
    }
    .icon {
     display: flex;
     justify-content: flex-end;
     .icon-i {
      font-size: 23px;
      color: #8d3de2;
     }
    }
    .container-inputs {
     margin-bottom: 15px;
     padding: 15px 20px;
     border-radius: 6px;
     background-color: #f7f5f8;
     .group-inputs {
      .input-static {
       padding: 0;
       margin: 0;
       input {
        text-align: center;
        width: 98%;
        background-color: #e9ecef;
        opacity: 1;
        border: 2px solid #8d3de2;
        padding: 3px 10px;
        cursor: not-allowed;
        font-size: 14px;
        height: 100%;
       }
      }

      .input-name {
       padding: 0;
       margin: 0 0;
       input {
        text-align: center;
        width: 99%;
        background-color: #f7f5f8;
        opacity: 1;
        border: 2px solid #8d3de2;
        padding: 3px 10px;
        font-size: 14px;
        height: 100%;
        color: #212529;
       }
      }
      .input-domain {
       position: relative;
       padding: 0;
       margin: 0;
       .container-serching-input {
        display: flex;
        justify-content: space-between;
        text-align: center;
        width: 99%;
        background-color: #f7f5f8;
        opacity: 1;
        border: 2px solid #8d3de2;
        padding: 3px 10px;

        span {
        }
        .btn-dropDown {
         .fa-chevron-circle-down {
          color: #8d3de2;
          font-size: 17px;
          cursor: pointer;
         }
        }
       }
       .chosen-drop {
        background: #fff;
        border: 2px solid #8d3de2;
        border-radius: 3px;
        position: absolute;
        width: 99%;
        margin-top: 3px;
        z-index: 9999999;
        height: 172px;
        overflow: auto;

        .container-search {
         position: relative;
         .chosen-search {
          position: static;
          input {
           width: 100%;
           border: 1px solid #8d3de2;
           color: #8d3de2;
           padding: 5px 6px;
           text-align: center;
          }
          ::placeholder {
           color: #8d3de2;
           font-size: 12px;
          }
         }
        }

        .chosen-results {
         width: 100%;
         padding: 0;
         margin: 0;
         li {
          padding: 4px 10px;
          width: 100%;
          list-style: none;
          cursor: pointer;
          border-bottom: 1px solid #8d3de2;
          border-right: 1px solid #8d3de273;
         }
         li:hover {
          background-color: #2ec1ff;
          color: #fff;
         }
         li:last-child {
          border: none;
         }
        }
       }
       .chosen-drop::-webkit-scrollbar {
        width: 10px;
       }
       .chosen-drop::-webkit-scrollbar-track {
        background-color: #eee;
       }
       .chosen-drop::-webkit-scrollbar-thumb {
        background-color: #8d3de28a;
       }
       .chosen-drop::-webkit-scrollbar-thumb:hover {
        background-color: #8d3de2;
       }
      }
      .btnCheck {
       padding: 0;
       margin: 0;
       button {
        border: 2px solid #8d3de2;
        border-radius: 3px;
        background-color: #936ce2;
        text-align: center;
        font-size: 14px;
        width: 100%;
        height: 100%;
       }
      }
     }
    }
   }
  }
  .active {
   background-color: #0d235a;
   color: blanchedalmond;
  }
 }
 .scrollingPanelContainer {
  .title-order-summary {
   @include style-headers;
   padding: 10px 31px;
   h5 {
    @include style-text-header;
    font-size: 15px;
    text-align: center;
   }
   .fa-file-invoice-dollar {
    @include style-icon-befor-header;
   }
  }
  .order-summary {
   .PayCash {
    .invoice-number {
     display: flex;
     justify-content: space-between;
     margin: 10px 0;

     h5 {
      color: map-get($color-texts, color-puy);
      font-size: 17px;
      margin: 0;
      text-align: center;
      font-family: $Paragraph-font;
      margin-bottom: 5px;
     }
     p {
      color: map-get($branding, them-color-btn);
      font-size: 17px;
      font-weight: 600;
      margin: 0;
      text-align: center;
      font-family: $Numbers-font;
      margin-bottom: 5px;
     }
    }
    .Requirements {
     display: flex;
     justify-content: space-between;
     margin: 10px 0;

     h5 {
      color: map-get($color-texts, color-puy);
      font-size: 17px;

      margin: 0;
      text-align: center;
      margin-bottom: 5px;

      font-family: $Paragraph-font;
     }
     p {
      color: map-get($branding, them-color-btn);
      font-size: 17px;
      font-weight: 600;
      margin: 0;
      text-align: center;
      font-family: $Numbers-font;
      margin-bottom: 5px;
     }
    }
    .MakeAnAppointment {
     display: flex;
     justify-content: space-between;
     margin: 10px 0;
     h5 {
      color: map-get($color-texts, color-puy);
      font-size: 17px;
      line-height: 33px;
      margin: 0;
      text-align: center;
      font-family: $Paragraph-font;
      margin-bottom: 5px;
     }
     button {
      border: none;
      padding: 7px 21px;
      background-color: #0d235a;
      border-radius: 3px;
      color: blanchedalmond;
     }
    }
   }
   .BankTransfer {
    .BankTransfer-Details {
     .Details {
      display: flex;
      justify-content: space-between;
      span {
       color: map-get($color-texts, color-puy);
       font-size: 17px;

       margin: 0;
       text-align: center;
       margin-bottom: 5px;
       font-family: $Paragraph-font;
      }
      .info-bank {
       color: map-get($branding, them-color-btn);
       font-size: 17px;
       font-weight: 600;
       margin: 0;
       text-align: center;
       font-family: $Numbers-font;
       margin-bottom: 5px;
      }
     }
    }
   }
   .SadadService {
    .sadad {
     @include product-info;
     padding-top: 35px;
     padding-bottom: 35px;
     .name-methode-payment {
      h4 {
       color: map-get($branding, them-color-btn);
       font-size: 22px;
       margin: 0;
       text-align: center;
       font-family: $Paragraph-font;
       margin-bottom: 30px;
       font-weight: 700;
       text-transform: uppercase;
      }
     }
     .section-form {
      span {
       color: map-get($color-texts, color-puy);
       font-size: 14px;
       margin: 0;
       text-align: center;
       font-family: $Numbers-font;
       margin-bottom: 20px;
      }

      .inputs {
      }
     }
     .PrivacyPolicy {
      @include PrivacyPolicy;
      label {
       span {
        margin-bottom: 0;
       }
      }
     }
     .group-btn {
      margin-top: 16px;
      display: flex;
      justify-content: space-evenly;
      button {
       width: 100%;
       padding: 8px 0;
       color: #fff;
       background: #06155a;
       border: none;
       border-radius: 3px;
      }
      .Close {
       color: #fff;
       background: #06155a;
      }
     }
    }
   }
   .TadawulService {
    .tadawul {
     @include product-info;
     padding-top: 35px;
     padding-bottom: 35px;
    }
    .name-methode-payment {
     h4 {
      color: map-get($branding, them-color-btn);
      font-size: 22px;
      margin: 0;
      text-align: center;
      font-family: $Paragraph-font;
      margin-bottom: 30px;
      font-weight: 700;
      text-transform: uppercase;
     }
    }
    .detailes {
     p {
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      font-family: $Paragraph-font;
      text-align: center;
      strong {
       color: #f59d1d;
      }
     }
    }
    .PrivacyPolicy {
     @include PrivacyPolicy;
    }
    .btnContinue {
     display: flex;
     justify-content: center;
     align-content: center;
     align-items: center;
     button {
      border: none;
      padding: 8px 35px;
      border-radius: 3px;
      background-color: #0d235a;
      color: #fff;
      font-weight: 500;
      font-family: $Paragraph-font;
     }
    }
   }
   .CreditCardPayment {
    .CreditCard {
     @include product-info;
     padding-top: 35px;
     padding-bottom: 35px;
     .namePayment {
      h4 {
       color: map-get($branding, them-color-btn);
       font-size: 22px;
       margin: 0;
       text-align: center;
       font-family: $Paragraph-font;
       margin-bottom: 30px;
       font-weight: 700;
       text-transform: uppercase;
      }
     }
     .detailes {
      p {
       font-size: 14px;
       font-weight: 500;
       color: #666666;
       font-family: $Paragraph-font;
       text-align: center;
       strong {
        color: #f59d1d;
       }
      }
     }
     .bodyFormInputs {
      .section-form {
       .form {
        span {
         color: map-get($color-texts, color-puy);
         font-size: 14px;
         margin: 0;
         text-align: center;
         font-family: $Numbers-font;
         margin-bottom: 20px;
        }

        .PrivacyPolicy {
         @include PrivacyPolicy;
         label {
          span {
           margin-bottom: 0;
          }
         }
        }
        .group-btn {
         margin-top: 16px;
         display: flex;
         justify-content: space-evenly;
         button {
          width: 100%;
          padding: 8px 0;
          color: #fff;
          background: #06155a;
          border: none;
          border-radius: 3px;
         }
         .Close {
          color: #fff;
          background: #06155a;
         }
        }
       }

       .inputs {
       }
      }
     }
    }
   }
   .aleart-secure {
    color: #fff;
    background-color: #06a72d;
    border-color: #06a72d;
    border-radius: 3px;
    font-size: 13px;
    text-align: center;
    font-weight: 400;
    margin-top: 1rem;
    padding: 10px 12px !important;
    p {
     margin: 0;
     padding: 0;
     .fa-lock {
      margin: 0 5px 0 0;
     }
    }
   }
  }

  .btnDomainContinue {
   margin: 13px 0;
   button {
    border: none;
    padding: 7px 10px;
    border-radius: 2px;
    color: #fff;
    background-color: #0d235a;
    width: 100%;
    font-size: 17px;
    font-weight: 400;
    margin: 0;
    .fa-arrow-circle-right {
     @include style-icon-befor-header;
     margin: 0 5px 0 0;
    }
   }
   .continue {
    background-color: #8d3de2;
   }
  }
  .PaymentCards {
   margin: 20px 0;
  }
 }
}
#PaymentMethods::before {
 content: "";
 position: absolute;
 bottom: -1px;
 left: 0;
 width: 100%;
 height: 200px;
 background-color: #0f0e2b;
 clip-path: polygon(100% 69%, 0 100%, 100% 100%);
}

</style>
