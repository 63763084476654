<template>
 <div>
 <Navigation/>
  <Wallet />
  <Partners />
  <Footer />
 </div>
</template>

<script>
import Wallet from "../components/Wallet.vue";

import Partners from "../../home/components/Partners.vue";
import Footer from "../../home/components/Footer.vue";
import Navigation from "../../home/components/Navigation.vue";
export default {
 components: { Wallet, Partners, Footer, Navigation },
};
</script>

<style lang="sass" scoped></style>
