<template>
 <div id="Wallet">
  <div class="headerContacts">
   <b-container>
    <b-row>
     <b-col class="container-title" lg="6" md="12">
      <div class="title-brief">
       <p>My Wallet.</p>
       <h2>Top up your wallet now</h2>
      </div>
      <div class="walletBalance">
       <b-container>
        <b-row>
         <b-col
          ><div class="invoicePrice">
           <div class="name-price">Invoice Price</div>
           <div class="price-Price">1700 <span>DLY</span></div>
          </div>
         </b-col>
         <b-col
          ><div class="walletBalance">
           <div class="name-price">Wallet Balance</div>
           <div class="price-wallet">2300 <span>DLY</span></div>
          </div>
         </b-col>
        </b-row>
       </b-container>
      </div>
     </b-col>
     <b-col class="container-img" lg="6" md="12"
      ><img src="../assets/Wallet-cover.png" alt="about"
     /></b-col>
    </b-row>
   </b-container>
  </div>
  <PaymentMethods />
 </div>
</template>

<script>
import PaymentMethods from "../../cart/components/PaymentMethods.vue";
export default {
 name: "Wallet",
 components: { PaymentMethods },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#Wallet {
 .headerContacts {
  position: relative;
  padding-top: 70px;
  padding-bottom: 80px;
  background-color: #0e0b2b;
  .container-title {
   padding-top: 81px;
   .title-brief {
    h2 {
     padding: 0 0 30px 0;
     font-weight: 800;
     font-size: 40px;
     color: #008aff;
     font-family: "Montserrat", sans-serif !important;
     letter-spacing: 1px;
     text-transform: uppercase;
    }
    p {
     margin-top: 54px;
     font-size: 17px;
     line-height: 26px;
     font-family: "Montserrat", sans-serif !important;
     transition: 0.2s linear;
     color: blanchedalmond;
    }
   }
   .walletBalance {
    .invoicePrice,
    .walletBalance {
     .name-price {
      margin-top: 54px;

      font-family: $Numbers-Price;
      font-size: 22px;
      line-height: 26px;
      font-family:$Paragraph-font;
      transition: 0.2s linear;
      color: blanchedalmond;
      text-align: center;
     }
     .price-Price,
     .price-wallet {
      font-weight: 700;
      font-size: 22px;
      padding: 20px 14px;
      color: #fff;
      font-family: $Numbers-Price;
      margin: 10px 0;
      -webkit-box-shadow: 5px 5px 5px 0px #000000,
       inset 4px 4px 15px 0px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
      box-shadow: 5px 5px 5px 0px #0d235a, inset 4px 4px 15px 0px #0d235a,
       5px 5px 15px 5px #f8f9f700;
      border-radius: 2px;
      text-align: center;
     }
    }
   }
  }
  .container-img {
   padding-left: 25px;
   img {
    width: 100%;
   }
  }
 }
 .headerContacts::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  clip-path: polygon(100% 69%, 0 102%, 103% 100%);
 }
}
</style>
